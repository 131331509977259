import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import { useLogin } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import styles from '../steps.module.scss';
import InputForm from './InputForm';
import useLoginInputFormState from './useLoginInputFormState';

export default function LoginStep(
  {
    onPhoneNumberSuccess,
    onPhoneNumberDoesNotExist,
    currentPhoneNumber,
    forceLogin,
    textMapping,
  }) {
  const { t } = useTranslation();

  const inputFormState = useLoginInputFormState();
  const { phone_number: phoneNumber } = inputFormState.value;

  const disabled = !inputFormState.isValid;

  const onSuccess = (response) => {
    onPhoneNumberSuccess(phoneNumber);
  };

  const onError = ({ response }) => {
    onPhoneNumberDoesNotExist(phoneNumber);
  };

  const { isLoading, login } = useLogin({ onSuccess, onError });
  const { setValue } = inputFormState;

  const handleSubmit = React.useCallback(() => {
    login({ phoneNumber });
  }, [login, phoneNumber]);

  React.useEffect(() => {
    if (currentPhoneNumber) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [currentPhoneNumber, setValue]);

  React.useEffect(() => {
    if(forceLogin && phoneNumber && String(phoneNumber).length === 10) {
      handleSubmit();
    }
  }, [forceLogin, phoneNumber, handleSubmit]);

  return (
    <div className='pt-2'>
      <h2 className={styles.title}>
        {t(`login.header.${textMapping}_title`)}
      </h2>
      <p className={styles.subtitle}>{t('login.header.subtitle')}</p>
      <InputForm inputFormState={inputFormState} useSepStyling />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <div className='mt-4 d-block text-center'>
        <Button
          className={cx(styles.cta, {
            [styles.disabled]: disabled,
          })}
          onClick={handleSubmit}
          loading={isLoading}
          disabled={disabled}
        >
          {t('login.login')}
        </Button>
      </div>
      <p className={cx(styles.footer, 'mt-3')}>
        {t('login.footer.text')}
        <a href="tel:+(726) 202-1213">{t('login.footer.number')}</a>
      </p>
    </div>
  );
}

LoginStep.propTypes = {
  onPhoneNumberSuccess: PropTypes.func,
  onPhoneNumberDoesNotExist: PropTypes.func.isRequired,
  currentPhoneNumber: PropTypes.string,
  forceLogin: PropTypes.bool,
  textMapping: PropTypes.string,
};

LoginStep.defaultProps = {
  currentPhoneNumber: "",
};
