import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Checkbox from '@separate/components/Checkbox';
import { useTranslation } from '@separate/hooks/translation';
import styles from './terms.module.scss';

export default function Terms(props) {
  const { checked, onChange } = props;

  const { t } = useTranslation();

  return (
    <>
      <p className={styles.description}>
        {t('login.sms.text')}
      </p>
      <ul className={styles.bullet}>
        <li>{t('login.sms.bullet1')}</li>
        <li>{t('login.sms.bullet2')}</li>
        <li>{t('login.sms.bullet3')}</li>
      </ul>
      <div className="d-flex flex-row mb-4">
        <Checkbox
          checked={checked}
          onChange={onChange}
          className={styles.check}
          id="accept_terms"
        />
        <label
          htmlFor="accept_terms"
          className={cx(styles.label, "ps-3")}
        >
          {t('login.accept_terms')}{" "}
          <Link href="/legal/terms-and-conditions">
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('login.terms_and_conditions')}
            </a>
          </Link>
        </label>
      </div>
    </>
  );
}

Terms.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};
