import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import TextInput from '@separate/components/TextInput';
import { useTranslation } from '@separate/hooks/translation';
import { inputFormStateType } from '@separate/types';
import styles from '../steps.module.scss';

export default function InputForm(props) {
  const { onSubmit, onResend, inputFormState, loading } = props;
  const { t } = useTranslation();

  return (
    <div>
      <TextInput
        type="tel"
        name="confirmation"
        mask="99999"
        maskChar=""
        unmasked
        inputFormState={inputFormState}
      />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <p>
        {t('login.resend_label')}
        <a className={styles.resendLink} onClick={onResend}>{t('login.resend_code')}</a>
      </p>
      <Button loading={loading} onClick={onSubmit} disabled={!inputFormState.isValid} className={styles.submitButton}>
        {t('login.submit_confirmation')}
      </Button>
    </div>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  onSubmit: PropTypes.func,
  onResend: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

InputForm.defaultProps = {
  inputFormState: {},
};
