import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import { RESEND_CODE_ERROR } from '@separate/constants/analytics';
import { useRegistration } from '@separate/hooks/login';
import { useResendCode } from '@separate/hooks/login';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { logEvent } from '@separate/utilities/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import { getUtmTags } from '@separate/utilities/utmTagsStore';
import styles from '../steps.module.scss';
import InputForm from './InputForm';
import Terms from './Terms';
import useRegistrationInputFormState from './useRegistrationInputFormState';

export default function RegistrationStep(
  {
    onPhoneNumberSuccess,
    onPhoneNumberAlreadyExist,
    currentPhoneNumber,
    textMapping,
  }) {
  const locale = useLocale();
  const { t } = useTranslation();
  const prevent2faForNewUsers = isFeatureEnabled('prevent_2fa_for_new_users');
  const [checked, setChecked] = React.useState(false);

  const { isLoading: isResendLoading, resendCode } = useResendCode({ onError: onResendError });
  const inputFormState = useRegistrationInputFormState();
  const { value: userInputs, setValue } = inputFormState;

  const disabled = !inputFormState.isValid || !checked;

  function onResendError(err) {
    logEvent(RESEND_CODE_ERROR, { result: err });
  }

  React.useEffect(() => {
    if (setValue) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [setValue, currentPhoneNumber]);

  const onSuccess = () => {
    if(prevent2faForNewUsers) {
      resendCode({ phoneNumber: userInputs.phone_number });
    }
    onPhoneNumberSuccess(userInputs.phone_number);
  };

  const onError = ({ response }) => {
    let errorMessage;

    switch(response.status){
    case 422:
      errorMessage = t(`validations.invalid_phone_number`);
      break;

    case 409:
      errorMessage = t(`validations.phone_already_taken`);
      break;
    }

    const fieldErrors = {
      phone_number: [
        errorMessage,
      ],
    };

    inputFormState.mergeErrors(fieldErrors);
  };

  const { isLoading: isRegistrationLoading, registration } = useRegistration({ onSuccess, onError });

  const handleSubmit = () => {
    registration(
      {
        firstName: userInputs.first_name,
        lastName: userInputs.last_name,
        phoneNumber: userInputs.phone_number,
        language: locale,
        data: getUtmTags(),
      }
    );
  };

  const isLoading = isRegistrationLoading || isResendLoading;

  return (
    <div className='pt-2'>
      <h2 className={styles.title}>
        {t(`login.header.${textMapping}_title_two`)}
      </h2>
      <InputForm inputFormState={inputFormState} useSepStyling />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <Terms
        checked={checked}
        onChange={setChecked}
      />
      <div className='d-block text-center mt-5 mb-4'>
        <Button
          loading={isLoading}
          onClick={handleSubmit}
          disabled={disabled}
          className={cx(styles.cta, {
            [styles.disabled]: disabled,
          })}
        >
          {t('login.sign_up')}
        </Button>
      </div>
    </div>
  );
}

RegistrationStep.propTypes = {
  onPhoneNumberSuccess: PropTypes.func.isRequired,
  onPhoneNumberAlreadyExist: PropTypes.func.isRequired,
  currentPhoneNumber: PropTypes.string,
  textMapping: PropTypes.string,
};

RegistrationStep.defaultProps = {
  currentPhoneNumber: "",
};
