import React from 'react';
import PropTypes from 'prop-types';
import LoginModal from '@separate/components/LoginModal';
import NewLoginModal from '@separate/components/NewLoginModal';
import { isFeatureEnabled } from '@separate/utilities/features';

export default function RenderLoginModal({ show, onHide }) {
  const allowNew2FAModal = isFeatureEnabled('allow_new_2fa_modal');

  return (
    <>
      {allowNew2FAModal ? (
        <NewLoginModal show={show} onHide={onHide} />
      ) : (
        <LoginModal show={show} onHide={onHide} />
      )}
    </>
  );
}

RenderLoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
