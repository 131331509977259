import * as React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from '@separate/hooks/translation';
import { idType } from '@separate/types';
import { scrollToTop } from '@separate/utilities/window';
import styles from './index.module.scss';
import ConfirmationCode from './steps/ConfirmationCode';
import LoginStep from './steps/LoginStep';
import RegistrationStep from './steps/RegistrationStep';

const LOGIN_STEP = 'login';
const REGISTRATION_STEP = 'register';
const CONFIRMATION_STEP = 'confirmationCode';

export default function Login(props) {
  const { onSuccess, providedPhoneNumber } = props;

  const [step, setStep] = React.useState(LOGIN_STEP);
  const [phoneNumber, setPhoneNumber] = React.useState(providedPhoneNumber);
  const [forceLogin, setForceLogin] = React.useState(!!providedPhoneNumber);

  React.useEffect(() => {
    setPhoneNumber(providedPhoneNumber);
  }, [providedPhoneNumber]);

  const { t } = useTranslation();

  function onPhoneNumberSuccess(value) {
    setPhoneNumber(value);
    setStep(CONFIRMATION_STEP);
  }

  function onPhoneNumberDoesNotExist(value) {
    setPhoneNumber(value);
    setStep(REGISTRATION_STEP);
  }

  function onPhoneNumberAlreadyExist(value) {
    setPhoneNumber(value);
    goBack();
  }

  function goBack() {
    setForceLogin(false);
    setStep(LOGIN_STEP);
  }

  function onConfirmationCodeSuccess() {
    scrollToTop();
    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <>
      {step !== LOGIN_STEP && (
        <Row className="mb-3">
          <Col md={10}>
            <a className="backLink" onClick={goBack}>{t('login.back_to_login')}</a>
          </Col>
        </Row>
      )}
      {step !== CONFIRMATION_STEP && (
        <div className={`${styles.content} mb-4`}>
          <h2>{t('login.congrats_in_joining')}</h2>
          <p>{t('login.we_are_ready_to_help')}</p>
        </div>
      )}
      {step === REGISTRATION_STEP && (
        <>
          <RegistrationStep
            onPhoneNumberSuccess={onPhoneNumberSuccess}
            onPhoneNumberAlreadyExist={onPhoneNumberAlreadyExist}
            currentPhoneNumber={phoneNumber}
          />
        </>
      )}
      {step === LOGIN_STEP && (
        <>
          <LoginStep
            onPhoneNumberSuccess={onPhoneNumberSuccess}
            onPhoneNumberDoesNotExist={onPhoneNumberDoesNotExist}
            currentPhoneNumber={phoneNumber}
            providedPhoneNumber={providedPhoneNumber}
            forceLogin={forceLogin}
          />
        </>
      )}
      {step === CONFIRMATION_STEP && (
        <ConfirmationCode
          phoneNumber={phoneNumber}
          onConfirmationCodeSuccess={onConfirmationCodeSuccess}
        />
      )}
    </>
  );
}

Login.propTypes = {
  onSuccess: PropTypes.func,
  providedPhoneNumber: idType,
};

Login.defaultProps = {
  providedPhoneNumber: "",
};
